<template lang="pug">
  .simulator
    article
      h5 Probador de estado de branch
      b-form-input(v-model="text" placeholder="Ingrese el nombre de la compañáa")
      b-button(v-if="!inTesting") Iniciar
      b-button(v-else variant="danger") Parar

</template>

<script>
export default {
  name: "Simulator",

  data: () => ({
    inTesting: false,
    company: ""
  })
};
</script>
